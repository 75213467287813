<template>
	<b-card>
		<ValidationObserver>
			<form @submit.prevent="saveItem">
				<b-row>
					<b-col cols="12" md="6">
						<h3 class="mb-3">Bundling Services</h3>
						<div class="form-group">
							<label>Nama</label>
							<validation-provider
								name="description"
								rules="required"
								v-slot="{ errors, classes }"
							>
								<input 
									type="text" 
									class="form-control" 
									v-model="formRekomendasi.name"
									:class="classes"
									:state="errors.length > 0 ? false:null"
									placeholder="Nama Bundling"
								>
								<small
									v-for="(validation, index) in validations.name"
									:key="`errorName${index}`"
									class="text-danger"
								>{{ validation }}</small>
							</validation-provider>
						</div>
						<div class="text-right">
							<b-button
								variant="primary"
								class="btn waves-effect waves-float waves-light btn-primary mt-1"
								@click="addFeatures"
							>
								Add
							</b-button>
						</div>
						<div
							v-for="(feature, index) in formRekomendasi.features"
							:key="feature.uuid"
						>
							<div class="d-flex justify-content-between align-items-center mb-1">
								<b-button
									variant="danger"
									class="btn waves-effect waves-float waves-light btn-danger"
									@click="removeFeatures(index)" v-show="index > 0"
								>
									Delete Features
								</b-button>
							</div>
							<div class="form-group">
								<label>Fitur</label>
								<v-select
									v-model="serviceSelected[index]"
									:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
									label="name"
									placeholder="-- Pilih Fitur --"
									:options="services"
									@input="changeService($event, index)"
								/>
								<small
									v-for="(validation, key) in validations['features.' + index + '.uuid']"
									:key="`errorName${key}`"
									class="text-danger"
								>{{ validation }}</small>
							</div>
							<div class="form-group">
								<label>Benefit Count</label>
								<b-form-spinbutton
									id="demo-sb"
									v-model="feature.benefit_count"
									:min="serviceSelected[index].qty"
									max="1000"
									:step="serviceSelected[index].qty"
									:value="feature.qty * serviceSelected[index].qty"
									@change="benefitMultiple($event, index)"
									
								/>
								<small
									v-for="(validation, key) in validations['features.' + index + '.benefit_count']"
									:key="`errorName${key}`"
									class="text-danger"
								>{{ validation }}</small>
							</div>
						</div>
					</b-col>
					<b-col cols="12" md="6">
						<validation-provider
							name="permissions"
							rules="required"
						>
							<h3 class="mb-3">Segmentasi</h3>
							<div class="form-group" v-for="(items, key) in segments" :key="key">
								<input type="hidden" :value="items.key" :ref="`val${items.key}`">
								<label :id="'input_segment_' + items.key">{{ items.label }}</label>
								<validation-provider
									name="segments"
									rules="required"
									v-slot="{ errors, classes }"
								>
									<input 
										type="email" 
										:id="'input_segment_' + items.key"
										class="form-control"
										:ref="items.key"
										:class="classes"
										:state="errors.length > 0 ? false:null"
										v-if="items.key == 'email'"
										v-model="inputSegment[items.key]"
									>
									<v-select
										label="name"
										:id="'input_segment_' + items.key"
										v-model="inputSegment[items.key]"
										:options="optionSearch"
										v-if="vSelectTagKeys.includes(items.key)"
										placeholder="-- Pilih --"
										:ref="items.key"
										@search="onSearch"
										:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
										:reduce="option => option.name"
									/>
									<select 
										class="form-control" 
										:id="'input_segment_' + items.key"
										v-model="inputSegment[items.key]"
										:class="classes"
										:state="errors.length > 0 ? false:null"
										v-if="regularSelectTagKeys.includes(items.key)"
										:ref="items.key"
									>
										<option value="">Any</option>
										<option :value="select.value" v-for="(select , index) in items.available_answer" :key="index">
											{{ select.label }}
										</option>
									</select>
									<small
										v-for="(validation, key) in validations['segmentations.' + key + '.key']"
										:key="`errorName${key}`"
										class="text-danger"
									>{{ validation }}</small>
								</validation-provider>
							</div>
							<div class="form-group">
								<label>Partnership</label>
								<validation-provider
									name="partners"
									rules="required"
									v-slot="{ errors }"
								>
									<v-select
										v-model="partnerSelected"
										:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
										:state="errors.length > 0 ? false:null"
										label="name"
										multiple
										placeholder="-- Pilih Partner --"
										:options="partners"
									/>
									<small
										v-for="(validation, index) in validations.partners"
										:key="`errorName${index}`"
										class="text-danger"
									>{{ validation }}</small>
								</validation-provider>
							</div>
						</validation-provider>
					</b-col>
				</b-row>
				
				<b-col cols="12" class="p-0 mt-2">
					<template v-if="isLoading">
						<button
							variant="primary"
							class="btn waves-effect waves-float waves-light btn-primary"
							disabled
						>
							<b-spinner class="align-middle"></b-spinner>
							<strong>Loading...</strong>
						</button>
					</template>
					<template v-else>
						<button
							variant="primary"
							class="btn waves-effect waves-float waves-light btn-primary"
						>
							Save
						</button>
						&nbsp;
						&nbsp;
						<a
							@click="handleBackButton()"
							variant="secondary"
							class="btn waves-effect waves-float waves-light btn-secondary"
						>
							Cancel
						</a>
					</template>
				</b-col>
			</form>
		</ValidationObserver>
	</b-card>
</template>

<script>
import {
	BCard, 
	BFormGroup, 
	BFormRadio, 
	BFormFile,
	BRow, 
	BCol,
	BButton,
	BSpinner,
	BFormCheckbox,
	BFormSpinbutton
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver, configure } from 'vee-validate'
import { required } from '@validations'
import vSelect from 'vue-select'
import _ from 'lodash'
import { successNotification, errorNotification } from '@/auth/utils'
configure({
	classes: {
		valid: 'is-valid',
		invalid: 'is-invalid',
		dirty: ['is-dirty', 'is-dirty'], // multiple classes per flag!
		// ...
	}
})
export default {
	components: {
		BCard,
		BRow,
		BCol,
		vSelect,
		BFormFile,
		BFormGroup,
		BFormRadio,
		BButton,
		BSpinner,
		BFormCheckbox,
		BFormSpinbutton,
		ValidationProvider,
		ValidationObserver,
	},

	setup() {
		return {
			successNotification,
			errorNotification,
		}
	},

	data() {
		return {
			required,
			isLoading: false,
			formRekomendasi:{
				name: '',
				features:[],
				segmentations: [],
			},
			services: [],
			segments: [],
			serviceSelected:[],
			inputSegment: [],
			optionSearch :[],
			validations: '',
			partners: [],
			partnerSelected:[],
			vSelectTagKeys: [
				'sub_profesi_pengusaha',
				'sub_profesi_profesi',
				'sub_profesi_freelance'
			],
			regularSelectTagKeys: [
				'profesi',
				'sub_profesi_pegawai',
				'punya_npwp',
				'punya_efin',
				'punya_djp',
				'harta',
				'status_menikah',
				'profesi_istri',
				'surat_pisah_harta',
				'punya_anak',
				'profesi_anak'
			]
		}
	},

	watch: {
		inputSegment: {
			handler(value) {
				console.log(value);
			},
			deep: true,
		}
	},

	methods: {
		changeSegmentSelect(event, key) {
			this.inputSegment[key] = event.target.value
			console.log(event.target.value, key)
		},
		changeSegmentInput(event, key) {
			this.inputSegment[key] = event.value
			console.log(event.value, key)
		},
		handleBackButton() {
			history.back()
		},
		onSearch(search, loading) {
			if (search.length) {
				loading(true);
				this.multipleSelect(loading, search, this);
			} 
			else {
				this.loadSubprofession()
			}
		},

		loadPartner() {
			this.$http.get('admin/partners')
			.then(response => {
				response.data.data.forEach(element => {
					if (element.name == 'Hipajak' && this.partnerSelected.length <= 0) {
						this.partnerSelected.push(element) 
					}
					this.partners.push({'uuid':element.uuid, 'name':element.name})
				});
			})
		},

		loadSubprofession() {
			this.$http.get('admin/fill-questions/compliting-profile/sources/business-types?keyword'
			).then(response => {
				this.optionSearch = response.data.data
			})
		},

		multipleSelect: _.debounce((loading, search, vm) => {
			vm.$http.get('admin/fill-questions/compliting-profile/sources/business-types?keyword='+ search
			).then(response => {
				vm.optionSearch = response.data.data
				loading(false)
			}).catch(() => {
				loading(false)
			})
		}, 300),

		loadService() {
			this.$http.get('admin/services?type=all')
			.then(response => {
				const permissionData = response.data.data
				permissionData.forEach(element => {
					this.services.push({
						'uuid': element.uuid,
						'name': element.name,
						'qty': element.qty,
					})
				});
			})
		},

		loadSegment() {
			this.$http.get('admin/segmentations')
			.then(response => {
				this.segments = response.data.data
				for (const object of this.segments) {
					this.inputSegment[object.key] = ''
				}
			})
			for (let index = 0; index < this.segments.length; index++) {
				const element = this.segments[index];
				this.$refs[element.key][0].value = this.formRekomendasi.segmentations[index]?.value || ''
			}
		},

		benefitMultiple(event, index) {
			this.formRekomendasi.features[index].qty = event / this.serviceSelected[index].qty
		},

		cancelPhoto() {
			this.formRekomendasi.image = null,
			this.image_url = null
		},
		changePhoto(event) {
			this.formRekomendasi.image = event.target.files[0]
			this.showPhoto(event.target.files[0])
		},
		showPhoto(file) {
			const reader = new FileReader()
			reader.onload = e => {
				this.image_url = e.target.result
			}
			reader.readAsDataURL(file)
		},

		loadRekomendasi() {
			this.isLoading = true
			const params = this.$route.params.id
			this.$http.get('admin/features/recomendations/' + params)
			.then(response => {
				const permissionData = response.data.data
				this.formRekomendasi = permissionData
				
				if (this.formRekomendasi.partners.length > 0) {
					this.partnerSelected = this.formRekomendasi.partners;
				}

				this.image_url = this.formRekomendasi.image
				this.formRekomendasi.features
				this.formRekomendasi.features.forEach((value) => {
					this.serviceSelected.push({
						'uuid': value.uuid,
						'name': value.name,
						'qty': value.benefit_count / value.qty,
					})
				})	
				for (let index = 0; index < this.formRekomendasi.segmentations.length; index++) {
					const element = this.formRekomendasi.segmentations[index];
					this.inputSegment[element.key] = element.value
				}
				this.isLoading = false
			})
			.then(() => {
				this.loadService();
				this.loadPartner();
				this.loadSubprofession()
			})
		},
		changeService(service, index) {
			this.formRekomendasi.features[index].qty = 1
			this.formRekomendasi.features[index].benefit_count = 1 * service.qty
		},

		addFeatures() {
        	this.formRekomendasi.features.push({ 
				uuid: '',
				benefit_count: '',
				qty: '',
			});
			this.serviceSelected.push({ 
				uuid: '',
				name: '',
				qty: '',
			});
		},

		removeFeatures(index) {
			this.formRekomendasi.features.splice(index, 1);
			this.serviceSelected.splice(index, 1);
		},

		saveItem() {
			console.log(this.inputSegment);
			this.isLoading = true
			const params = this.$route.params.id
			const payload = new FormData()
			payload.append("name", this.formRekomendasi.name)
			if (this.formRekomendasi.features !== null) {
				this.formRekomendasi.features.forEach((features, index) => {
					Object.keys(features).forEach(key => {
						if(key != 'description' || key != 'slug' || key != 'group') {
							if (key == 'uuid') {
								payload.append('features' + '['+ index + '][' + key + ']', this.serviceSelected[index].uuid);
							} else if (key == 'qty') {
								payload.append('features' + '['+ index + '][' + key + ']', features[key]);
							} else if (key == 'benefit_count') {
								payload.append('features' + '['+ index + '][' + key + ']', features[key]);
							}
						}
					});
				});
			}

			for (let index = 0; index < this.partnerSelected.length; index++) {
				const partnerUuid = this.partnerSelected[index]['uuid'];
				payload.append('partners[]', partnerUuid)
			}

			for (let index = 0; index < this.segments.length; index++) {
				const element = this.segments[index];
				payload.append("segmentations[" + index + "][key]", this.$refs[`val${element.key}`][0].value)
				payload.append("segmentations[" + index + "][value]", this.$refs[element.key][0].value || '')
			}
			payload.append("_method", 'PATCH')
			this.$http.post(`admin/features/recomendations/${params}`, payload , {
				headers: { 'Content-Type': 'multipart/form-data' },
			})
			.then(response => {
				successNotification(this, 'Success', 'Rekomendasi Service Berhasil diedit!')
				this.$router.push({ name: 'rekomendasi-service' })
			}).catch(error => {
				if (error.response.data.meta.messages) {
					errorNotification(this, 'Oops!', error.response.data.meta.messages) 
					this.isLoading = false
				}
				if (error.response.data.meta.validations.name || error.response.data.meta.validations.status || error.response.data.meta.validations.price ) {
					this.validations = error.response.data.meta.validations
					errorNotification(this, 'Nama', this.validations.name.join(' '))
				} else {
					this.validations = error.response.data.meta.validations
					errorNotification(this, 'Oops!', 'Input ada yang tidak terisi')
				}
				this.isLoading = false
			})
		}
	},
	created() {
		this.loadSegment();
		this.loadRekomendasi();
	}
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
	.form-check-inline {
		flex-wrap: wrap;
	}
	.images__hint {
		font-size: 10px;
		margin-bottom: 0;
	}
</style>